var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-4"},[_c('notification-box',{staticClass:"mb-4",attrs:{"flat":true,"type":"info"}},[_c('div',{staticClass:"text-label"},[_vm._v("Your new password must include:")]),_c('ul',{staticStyle:{"list-style-type":"square"}},[_c('li',[_vm._v("At least 8 characters")]),_c('li',[_vm._v("Uppercase and lowercase letters")]),_c('li',[_vm._v("At least one number")]),_c('li',[_vm._v("At least one special character: # ? ! @ $ % ^ & * -")])])]),_c('h3',{staticClass:"mb-2"},[_vm._v("Current password")]),_c('v-text-field',{attrs:{"outlined":"","type":"password","error-messages":_vm.showErrors.currentPassword && _vm.errorMessages.currentPassword
        ? _vm.errorMessages.currentPassword
        : null},on:{"blur":function($event){_vm.showErrors.currentPassword = true},"focus":function($event){_vm.showErrors.currentPassword = false}},model:{value:(_vm.local.currentPassword),callback:function ($$v) {_vm.$set(_vm.local, "currentPassword", $$v)},expression:"local.currentPassword"}}),_c('h3',{staticClass:"mb-2"},[_vm._v("New password")]),_c('v-text-field',{attrs:{"outlined":"","type":"password","error-messages":_vm.showErrors.newPassword && _vm.errorMessages.newPassword
        ? _vm.errorMessages.newPassword
        : null},on:{"blur":function($event){_vm.showErrors.newPassword = true},"focus":function($event){_vm.showErrors.newPassword = false}},model:{value:(_vm.local.newPassword),callback:function ($$v) {_vm.$set(_vm.local, "newPassword", $$v)},expression:"local.newPassword"}}),_c('div',{staticClass:"font-weight-medium mb-2"},[_vm._v("Confirm new password")]),_c('v-text-field',{attrs:{"type":"password","outlined":"","error-messages":_vm.showErrors.confirmPassword && _vm.errorMessages.confirmPassword
        ? _vm.errorMessages.confirmPassword
        : null},on:{"blur":function($event){_vm.showErrors.confirmPassword = true},"focus":function($event){_vm.showErrors.confirmPassword = false}},model:{value:(_vm.local.confirmPassword),callback:function ($$v) {_vm.$set(_vm.local, "confirmPassword", $$v)},expression:"local.confirmPassword"}}),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"text":"","disabled":_vm.processing,"depressed":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.closeLabel))]),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v("Save")])],1),_vm._m(0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"text-label"},[_vm._v("Can't remember your current password?")]),_vm._v(" You can change your password by logging out and using the 'Reset Password' option on the Log In page. ")])
}]

export { render, staticRenderFns }